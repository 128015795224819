import Container from './Container';
import btnTop from '@/assets/img/btn_top@2x.png';
import btnTopMobile from '@/assets/img/btn_top_m@2x.png';
import SnsFacebook from '@/assets/img/ico_sns_facebook@2x.png';
import SnsInstagram from '@/assets/img/ico_sns_instagram@2x.png';
import SnsTwitter from '@/assets/img/ico_sns_twitter@2x.png';
import SnsYoutube from '@/assets/img/ico_sns_youtube@2x.png';
import { breakpoint } from '@/helpers/BreakpointHelper';
import { isBrowser } from '@/helpers/BrowserHelper';
import { Link } from 'gatsby';
import React, { FC, useCallback } from 'react';
import styled from 'styled-components';

const FooterContainer = styled.footer`
  display: block;
  padding: 46px 0 58px 0;
  background: #f8f9fa;
  line-height: 24px;
  font-size: 14px;
  color: #828385;
  ${breakpoint(1120)} {
    padding: 46px 20px;
  }
  ${breakpoint(800)} {
    font-size: 12px;
    padding: 32px 20px;
  }
`;
const FooterPublicLink = styled.ul`
  overflow: hidden;
  margin-bottom: 24px;
  ${breakpoint(800)} {
    margin: 24px 0;
  }
  li {
    position: relative;
    float: left;
    margin-right: 8px;
    & + li {
      padding-left: 8px;
      &::before {
        content: '';
        display: block;
        width: 1px;
        height: 10px;
        background: #b7b8ba;
        position: absolute;
        left: 0;
        top: 50%;
        margin-top: -5px;
      }
    }
  }
  span {
    display: inline-block;
    border: solid 1px;
    border-color: #b7b8ba;
    padding-left: 5px;
    padding-right: 5px;
    padding-bottom: 1px;
    margin-right: 8px;
  }
`;
const FooterAddress = styled.div`
  font-size: 0;
  span {
    display: inline-block;
    font-size: 14px;
    ${breakpoint(800)} {
      font-size: 12px;
    }
    & + span {
      margin-left: 12px;
    }
    ${breakpoint(640)} {
      & + span {
        margin: 0 8px 0 0;
      }
    }
  }
`;
const FooterSnsList = styled.div`
  position: absolute;
  right: 0;
  top: 0;
  font-size: 0;
  ${breakpoint(1280)} {
    right: 20px;
  }
  ${breakpoint(800)} {
    position: static;
    text-align: center;
  }
  a {
    display: inline-block;
    width: 32px;
    height: 32px;
    background-size: 100%;
    background-position: center center;
    background-repeat: no-repeat;
    vertical-align: top;
    & + a {
      margin-left: 24px;
    }
  }
  .footer-sns--instagram {
    background-image: url(${SnsInstagram});
  }
  .footer-sns--youtube {
    background-image: url(${SnsYoutube});
  }
  .footer-sns--facebook {
    background-image: url(${SnsFacebook});
  }
  .footer-sns--twitter {
    background-image: url(${SnsTwitter});
  }
`;

const ButtonTop = styled.div`
  position: absolute;
  top: -120px;
  right: 0px;
  margin-bottom: 20px;
  width: 96px;
  height: 96px;
  z-index: 1;
  cursor: pointer;
  background-image: url(${btnTop});
  background-size: 100%;
  box-shadow: 24px 18px 40px 0 rgba(0, 0, 0, 0.08);
  ${breakpoint(`mobile`)} {
    width: 72px;
    height: 72px;
    background-image: url(${btnTopMobile});
    top: -90px;
  }
`;

const Footer: FC = () => {
  const scrollToTop = useCallback(() => {
    if (isBrowser) {
      window.scrollTo(0, 0);
    }
  }, []);
  return (
    <FooterContainer>
      <Container className="footer-container">
        <ButtonTop onClick={() => scrollToTop()} />
        <FooterSnsList>
          <a
            href="https://www.instagram.com/unicef_kr"
            target="_blank"
            className="footer-sns--instagram"
            rel="noreferrer"
          >
            <span className="for-a11y">유니세프 인스타그램</span>
          </a>
          <a
            href="https://www.youtube.com/user/unicefMedia/"
            target="_blank"
            className="footer-sns--youtube"
            rel="noreferrer"
          >
            <span className="for-a11y">유니세프 유튜브</span>
          </a>
          <a
            href="https://www.facebook.com/unicefkr"
            target="_blank"
            className="footer-sns--facebook"
            rel="noreferrer"
          >
            <span className="for-a11y">유니세프 페이스북</span>
          </a>
          <a
            href="https://twitter.com/unicefkorea"
            target="_blank"
            className="footer-sns--twitter"
            rel="noreferrer"
          >
            <span className="for-a11y">유니세프 트위터</span>
          </a>
        </FooterSnsList>
        <FooterPublicLink>
          <li>
            <Link to="/service">이용약관</Link>
          </li>
          <li>
            <Link to="/privacy">개인정보 처리방침</Link>
          </li>
          <li>
            <Link to="/location">오시는 길</Link>
          </li>
          <li>
            <Link to="/faq">FAQ</Link>
          </li>
          <li>
            <Link to="/sitemap">사이트맵</Link>
          </li>
          <span>후원문의 02.737.1004</span>
          <a href="https://www.acrc.go.kr/" target="_blank" rel="noreferrer">
            <span>공익제보</span>
          </a>
        </FooterPublicLink>

        <FooterAddress>
          <div>
            <span>사단법인 유니세프한국위원회</span>
            <span>대표자 : 정갑영</span>
            <span>고유번호 : 102-82-07606</span>
            <span>[04099] 서울시 마포구 서강로 60(창전동)</span>
          </div>
          <div>
            <span>
              COPYRIGHT @ THE KOREAN COMMITTEE FOR UNICEF. ALL RIGHT RESERVED
            </span>
          </div>
        </FooterAddress>
      </Container>
    </FooterContainer>
  );
};

export default Footer;
