import { Breakpoints } from '@/helpers/BreakpointHelper';
import React, { FC, ImgHTMLAttributes, useMemo } from 'react';
import styled from 'styled-components';

type ImgProps = ImgHTMLAttributes<HTMLImageElement>;

interface ImageProps extends Omit<ImgProps, 'src'> {
  pcSrc: string | undefined;
  mobileSrc: string | undefined;
}

const Image = styled<FC<ImageProps>>(({ pcSrc, mobileSrc, alt, ...props }) => {
  // URL 인코딩 (URL에 띄어쓰기가 들어가면 파싱에러 발생하여 인코딩 처리)
  const encodedPcSrc = useMemo(() => pcSrc && encodeURI(pcSrc), [pcSrc]);
  const encodedMobileSrc = useMemo(() => mobileSrc && encodeURI(mobileSrc), [
    mobileSrc,
  ]);

  return (
    <picture>
      <source
        srcSet={`${encodedMobileSrc} 2x`}
        media={`(max-width: ${Breakpoints.mobile}px)`}
        type="image/png"
      />
      <source srcSet={`${encodedPcSrc} 2x`} />
      <img
        {...props}
        src={encodedPcSrc}
        alt={alt}
        css={`
          width: 100%;
        `}
      />
    </picture>
  );
})``;

export default Image;
