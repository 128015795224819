import { breakpoint } from '@/helpers/BreakpointHelper';
import styled from 'styled-components';

const Container = styled.div<{
  paddingVertical?: boolean;
  flex?: boolean;
  maxWidth?: string;
}>`
  width: 100%;
  max-width: ${({ maxWidth }) => maxWidth || `1160px`};
  margin: 0 auto;
  padding-left: 20px;
  padding-right: 20px;

  &.by-mainLayout {
    padding-top: 120px;
    padding-bottom: 120px;
    margin-top: 120px;
    margin-bottom: 120px;
  }
  ${(props) => props.paddingVertical && `padding: 20px 0;`}
  ${(props) => props.flex && `display: flex;`}
`;

export default Container;
