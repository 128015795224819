import ArrowMore from '@/assets/img/ico_arrow@2x.png';
import IconFileAttach from '@/assets/img/ico_file_attach@2x.png';
import DatePick from '@/assets/img/icons/ico_datepicker@2x.png';
import IconDown from '@/assets/img/icons/ico_down_gray@2x.png';
import IconBlank from '@/assets/img/icons/ico_target_blank@2x.png';
import { breakpoint } from '@/helpers/BreakpointHelper';
import React, { FC } from 'react';
import { Helmet } from 'react-helmet';
import { createGlobalStyle, css } from 'styled-components';

const GlobalCSS = css`
  * {
    margin: 0;
    padding: 0;
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box;
  }
  *:before,
  *:after {
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box;
  }
  html,
  body {
    width: 100%;
    height: 100%;
    word-break: keep-all;
    letter-spacing: -0.8px;
  }
  #___gatsby {
    overflow: hidden;
  }
  // 폰트설정
  html,
  body,
  button {
    font-family: 'Noto Sans KR', sans-serif;
    font-size: 16px;
    color: #2d2926;
    ${breakpoint(`tablet`)} {
      font-size: 14px;
    }
  }
  body {
    min-width: 320px;
    -webkit-text-size-adjust: none;
  }
  ul,
  ol,
  li {
    list-style: none;
  }
  address,
  cite,
  code,
  em {
    font-style: normal;
    font-weight: normal;
  }
  .hide,
  caption,
  legend {
    line-height: 0;
    font-size: 1px;
    overflow: hidden;
  }
  img {
    vertical-align: top;
    max-width: 100%;
  }
  img,
  fieldset {
    border: 0;
  }
  select {
    font-size: 16px;
    ${breakpoint(`tablet`)} {
      font-size: 14px;
    }
  }
  label,
  img,
  input,
  select,
  textarea,
  button {
    color: #2d2926;
    vertical-align: middle;
    font-family: 'Noto Sans KR', sans-serif;
  }
  address,
  cite,
  code,
  em {
    font-style: normal;
    font-weight: normal;
  }
  table {
    width: 100%;
    border-spacing: 0;
    border-collapse: collapse;
    table-layout: fixed;
  }
  a,
  button {
    color: inherit;
    text-decoration: none;
    &:focus {
      outline: none;
    }
    &:hover {
      text-decoration: none;
    }
  }
  button {
    background: transparent;
    font-family: 'Noto Sans KR', sans-serif;
    font-size: inherit;
    border: none;
    :not(:disabled) {
      cursor: pointer;
    }
  }
  .dec,
  .header-dec {
    line-height: 2;
    letter-spacing: -1px;
  }
  .header-dec {
    margin-top: 32px;
  }
  /* Hidden Text For A11y */
  .for-a11y {
    display: block;
    z-index: -1;
    height: 1px;
    overflow: hidden;
    font-size: 1px;
    line-height: 1px;
    color: transparent;
    border: 0;
    padding: 0;
    opacity: 0;
    -ms-filter: 'progid:DXImageTransform.Microsoft.Alpha(Opacity=0)';
    filter: alpha(opacity=0);
    background: none;
    position: absolute;
    width: 1px;
    margin: 0;
  }
  caption {
    &.for-a11y {
      display: table-caption;
      height: 1px;
      margin-bottom: -1px;
      position: static;
    }
  }
  /* Form Element */
  input {
    outline: 0;
    width: 100%;
    font-size: 16px;
    font-family: 'Noto Sans KR', sans-serif;
    &::placeholder,
    &::-webkit-input-placeholder,
    &:-ms-input-placeholder {
      font-size: 14px;
      color: #b7b8ba;
    }
    &:disabled {
      &::placeholder,
      &::-webkit-input-placeholder,
      &:-ms-input-placeholder {
        color: #b7b8ba;
      }
    }

    ${breakpoint(`tablet`)} {
      font-size: 14px;
    }
  }
  input {
    &[type='tel'],
    &[type='time'],
    &[type='password'],
    &[type='search'],
    &[type='email'],
    &[type='file'],
    &[type='url'],
    &[type='number'],
    &[type='date'] {
      padding: 0 12px;
      outline: 0;
      &:disabled,
      &[readonly] {
        // 속성 추가 예정
      }
    }
    &[type='number'] {
      -moz-appearance: textfield;
    }
    &:focus {
      border: 1px solid #2589c9;
    }
    &::-webkit-outer-spin-button,
    &::-webkit-inner-spin-button {
      -webkit-appearance: none;
      margin: 0;
    }
  }
  textarea {
    outline: 0;
    width: 100%;
    height: 100%;
    padding: 16px;
    resize: none;
    font-size: 16px;
    font-family: 'Noto Sans KR', sans-serif;
    ${breakpoint(`tablet`)} {
      font-size: 14px;
    }
  }
  // datepicker
  .picker {
    width: 100%;
    .picker-input {
      display: block;
    }
    input[type='text'] {
      height: 56px;
      width: 100%;
    }
  }
  .date-pick {
    padding-left: 48px;
    background: url(${DatePick}) center left 12px no-repeat;
    background-size: 24px;
  }
  .mgt-8 {
    margin-top: 8px;
  }
  .mgb-8 {
    margin-top: 8px;
  }
  .ui-checkbox-block,
  .ui-radio-block {
    position: relative;
    z-index: 0;
    display: inline-block;
    & + .ui-checkbox-block,
    .ui-radio-block {
      margin-left: 16px;
    }
  }
  .form-flex {
    display: table;
    &.type-full {
      width: 100%;
    }
    &-cell {
      display: table-cell;
      vertical-align: middle;
    }
    &-cell + &-cell {
      padding-left: 8px;
    }
  }
  .ellipsis {
    color: #1cabe2 !important;
  }
  .ellipsis-underline {
    position: relative;
    &::after {
      content: '';
      display: block;
      width: 100%;
      height: 20px;
      background: #b2def1;
      position: absolute;
      left: 0;
      bottom: 0;
      z-index: -1;
    }
  }
  .gray {
    color: #828385;
  }
  .gray-underline {
    color: #828385;
    text-decoration: underline;
    &:hover {
      text-decoration: underline;
    }
  }

  /* Webkit */
  input::-webkit-input-placeholder {
    color: #b7b8ba;
    font-size: inherit;
  }
  textarea::-webkit-input-placeholder {
    color: #b7b8ba;
    font-size: inherit;
  }

  /* Firefox 4-18 */
  input:-moz-placeholder {
    color: #b7b8ba;
    font-size: inherit;
  }
  textarea:-moz-placeholder {
    color: #b7b8ba;
    font-size: inherit;
  }

  /* Firefox 19+ */
  input::-moz-placeholder {
    color: #b7b8ba;
    font-size: inherit;
  }
  textarea::-moz-placeholder {
    color: #b7b8ba;
    font-size: inherit;
  }

  /* IE10+ */
  input:-ms-input-placeholder {
    color: #b7b8ba;
  }
  textarea:-ms-input-placeholder {
    color: #b7b8ba;
  }
  .swiper-wrapper {
    position: relative;
    width: 100%;
    height: 100%;
    z-index: 1;
    display: flex;
    transition-property: transform;
    box-sizing: content-box;
  }
  .swiper-container {
    margin-left: auto;
    margin-right: auto;
    position: relative;
    overflow: hidden;
    list-style: none;
    padding: 0;
    z-index: 1;
  }
  .swiper-slide {
    flex-shrink: 0;
    width: 100%;
    height: 100%;
    position: relative;
    transition-property: transform;
  }
  .swiper-button-prev,
  .swiper-button-next {
    // container의 z-index가 1이기 때문에 버튼은 2로 세팅
    z-index: 2;
  }

  /* Todo 20210527 update ing */
  .support-direct-choice {
    EventCircle .support-direct-terms {
      width: 352px;
    }

    #gatsby-focus-wrapper {
      overflow: hidden;
    }
    .by-mainLayout {
      h2 {
        margin-bottom: 96px;
      }
      ${breakpoint(1280)} {
        h2 {
          margin-bottom: 40px !important;
        }
      }
    }
    .content-section--direct {
      background-color: #2589c9;
      height: 230px;
      padding: 52px 0;
      overflow: visible;
      > div {
        position: relative;
      }
      .support-direct-price {
        width: 544px;
        margin-left: 32px;
      }
      .support-direct-terms,
      .support-direct-price {
        display: inline-block;
      }
    }
    .support-price-custom {
      margin-top: 24px;
      position: absolute;
      left: 388px;
      width: 550px;
      &::after {
        content: '';
        display: table;
        clear: both;
      }
    }
    .card-supports {
      float: left;
    }

    ${breakpoint(1024)} {
      height: auto;
      .support-price-custom {
        position: static;
        float: none;
      }
      .card-supports {
        float: none;
        position: absolute;
        right: 20px;
        bottom: -100px;
      }
    }
    ${breakpoint(800)} {
      .support-price-custom {
        width: 100%;
        margin-top: 16px;
      }
      .card-supports {
        position: static;
      }
    }
  }

  .support-textbox {
    margin: 40px 0 0 0;
  }
  .footer-container {
    position: relative;
  }

  .pc-hide {
    display: none !important;

    ${breakpoint(`tablet`)} {
      display: block !important;
    }
  }
  .m-hide {
    ${breakpoint(`tablet`)} {
      display: none !important;
    }
  }
  .small-show {
    display: none;

    ${breakpoint(`mobile`)} {
      display: block;
    }
  }
  .small-hide {
    ${breakpoint(`mobile`)} {
      display: none !important;
    }
  }
  .flex {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    margin: 0 -16px;
    &.align-start {
      align-items: flex-start;
    }

    & > * {
      padding: 0 16px;
    }

    ${breakpoint(`tablet`)} {
      margin-left: -12px;
      margin-right: -12px;
      & > * {
        padding: 0 12px;
      }
    }
    ${breakpoint(`mobile`)} {
      margin-left: 0;
      margin-right: 0;
      & > * {
        padding: 0;
      }
    }
  }
  .img-box {
    display: block;
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    position: relative;
  }
  .a11y {
    overflow: hidden;
    position: absolute;
    top: -9999px;
    left: -9999px;
    width: 1px;
    height: 1px;
    font-size: 1px;
    line-height: 1;
  }

  .two-line {
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  .tag {
    display: inline-block;
    padding: 0 4px;
    line-height: 26px;
    border: 1px solid #1cabe2;
    color: #1cabe2;
    font-size: 14px;
    margin-right: 8px;
    margin-bottom: 8px;
  }

  .more {
    display: inline-block;
    padding-right: 30px;
    position: relative;
    line-height: 24px;
    &::before {
      content: '';
      display: block;
      width: 24px;
      height: 24px;
      background: url(${ArrowMore}) center no-repeat;
      background-size: cover;
      position: absolute;
      right: 0;
      top: 0;
    }
  }

  .link-down,
  .link-blank {
    display: inline-block;
    padding-right: 24px;
    position: relative;
    line-height: 24px;
    color: #1cabe2;
    text-decoration: none;
    font-weight: 700;
    text-decoration: underline;
    &:hover {
      text-decoration: underline;
    }
    &::after {
      content: '';
      display: block;
      width: 24px;
      height: 24px;
      background: url(${IconBlank}) center no-repeat;
      background-size: cover;
      position: absolute;
      right: 0;
      top: 0;
    }

    &.link-down {
      color: #2d2926;
      text-decoration: none;
      font-weight: 400;
      &::after {
        background-image: url(${IconDown});
      }
    }
  }
  .file-item {
    display: inline-block;
    padding-left: 32px;
    position: relative;
    color: #828385;
    line-height: 24px;

    &:before {
      content: '';
      display: block;
      width: 24px;
      height: 24px;
      background: url(${IconFileAttach}) center no-repeat;
      background-size: cover;
      position: absolute;
      left: 0;
      top: 50%;
      transform: translate(0, -50%);
    }
  }
  .blit-list {
    & > li {
      position: relative;
      padding-left: 16px;
      margin-bottom: 4px;

      &:last-child {
        margin-bottom: 0;
      }

      &::before {
        content: '';
        display: block;
        width: 4px;
        height: 4px;
        border-radius: 100%;
        position: absolute;
        left: 0;
        top: 12px;
        background: #1cabe2;
      }

      ${breakpoint(`tablet`)} {
        padding-left: 16px;
        line-height: 2;
        /* &::before {
          top: 10px;
        } */
      }
    }
    &.black {
      & > li {
        &::before {
          background: #2d2926;
        }
      }
    }
  }
  .swiper-container-fade {
    &.swiper-container-free-mode {
      .swiper-slide {
        transition-timing-function: ease-out;
      }
    }
    .swiper-slide {
      pointer-events: none;
      transition-property: opacity;
      .swiper-slide {
        pointer-events: none;
      }
    }
    .swiper-slide-active {
      &,
      & .swiper-slide-active {
        pointer-events: auto;
      }
    }
  }

  .haeder-dec {
    line-height: 2;
    margin-top: 32px;

    ${breakpoint(`tablet`)} {
      margin-top: 24px;
    }
  }

  table {
    margin-top: 32px;
    border-top: 3px solid #000;
    border-left: 1px solid #ddd;
    border-collapse: initial;

    th,
    td {
      height: 60px;
      line-height: 1.7;
      border-bottom: 1px solid #000;
      border-right: 1px solid #ddd;
      text-align: center;
      padding: 5px 7px;
    }

    td {
      height: 80px;
      line-height: 1.7;
      border-color: #ddd;
      text-align: center;
      padding: 5px;
    }

    tbody {
      th {
        background: #f8f9fa;
        border-bottom: 1px solid #ddd;
        text-align: left;

        strong {
          color: #3162af;
        }
      }
    }
    tr:last-child {
      border-bottom-color: #000;
    }

    .price {
      text-align: right;
    }
    .dec {
      text-align: center;
    }
  }
`;

// createGlobalStyle은 VScode에서 오토포멧팅이 안되서 css를 거치도록 구현
// 참고 : https://github.com/styled-components/vscode-styled-components/issues/175
const GlobalStyleComponent = createGlobalStyle`${GlobalCSS}`;

const GlobalStyle: FC = () => (
  <>
    <Helmet>
      <link rel="preconnect" href="https://fonts.gstatic.com" />
      <link
        href="https://fonts.googleapis.com/css2?family=Noto+Sans+KR:wght@300;400;700;900&display=swap"
        rel="stylesheet"
      />
    </Helmet>
    <GlobalStyleComponent />
  </>
);

export default GlobalStyle;
