import IconMoreArrow from '@/assets/img/icons/icon_moreview_link@2x.png';
import { breakpoint } from '@/helpers/BreakpointHelper';
import { Link } from 'gatsby';
import styled from 'styled-components';

export const H2 = styled.h2<{
  color?: string;
}>`
  font-size: 32px;
  position: relative;

  ${({ color }) => (color === `gray` ? `color: #b7b8ba;` : ``)}
  ${breakpoint(`tablet`)} {
    font-size: 20px;
  }
`;
export const H3 = styled.h3`
  font-size: 20px;
`;
export const H4 = styled.h4<{ weight?: 'normal'; color?: 'sky' }>`
  font-size: 16px;
  ${({ weight }) => {
    if (weight === `normal`) {
      return ` font-weight: normal; `;
    }
  }}
  ${({ color }) => {
    if (color === `sky`) {
      return ` color: #1cabe2; `;
    }
  }}
`;
export const H2_1 = styled.h2<{
  color?: string;
}>`
  font-size: 48px;

  ${breakpoint(`mobile`)} {
    font-size: 26px;
  }
`;
export const H2_2 = styled.h2<{
  color?: string;
}>`
  font-size: 20px;

  ${breakpoint(`tablet`)} {
    font-size: 16px;
  }
`;
export const H2_3 = styled.h2<{
  color?: string;
}>`
  font-size: 24px;

  ${breakpoint(`tablet`)} {
    font-size: 16px;
  }
`;
export const H3_1 = styled.h3<{
  color?: string;
}>`
  font-size: 24px;

  ${breakpoint(`tablet`)} {
    font-size: 16px;
  }
`;
export const H3_2 = styled.h3<{
  color?: string;
}>`
  font-size: 20px;

  ${breakpoint(`tablet`)} {
    font-size: 16px;
  }
`;
export const BigTit = styled.strong<{
  color?: string;
}>`
  display: inline-block;
  font-size: 40px;

  ${breakpoint(`tablet`)} {
  }
`;
export const midTit = styled.strong<{
  color?: string;
}>`
  display: inline-block;
  font-size: 24px;

  ${breakpoint(`tablet`)} {
    font-size: 16px;
  }
`;
export const SmallTit = styled.strong<{
  color?: string;
}>`
  display: inline-block;
  font-size: 20px;

  ${breakpoint(`tablet`)} {
    font-size: 16px;
  }
`;

export const MoreViewLink = styled(Link)`
  font-size: 20px;
  font-weight: bold;
  position: absolute;
  right: 0;
  top: 30px;
  padding-right: 32px;
  white-space: nowrap;

  &::after {
    content: '';
    display: block;
    width: 24px;
    height: 24px;
    background: url(${IconMoreArrow}) no-repeat center center;
    background-size: 100%;
    position: absolute;
    right: 0;
    top: 0;
  }

  ${breakpoint(`tablet`)} {
    top: 10px;
    font-size: 16px;
    padding-right: 26px;
    line-height: normal;
  }
`;

/* font renewal 20210526 */
interface TitProps {
  color?: 'sky' | 'white' | 'yellow' | 'gray';
  size?:
    | 's1'
    | 's1-5'
    | 's2'
    | 's3'
    | 's3-1'
    | 's4'
    | 's4-1'
    | 's5-l'
    | 's2-l'
    | 's6';
  weight?: 'normal' | `thin` | `black`;
}

export const Tit = styled.strong<TitProps>`
  display: inline-block;
  line-height: 1.56;
  letter-spacing: -0.8px;

  ${({ color }) => {
    if (color === `sky`) {
      return ` color: #1cabe2; `;
    }
    if (color === `white`) {
      return ` color: #fff; `;
    }
    if (color === `yellow`) {
      return ` color: #ffdc0e; `;
    }
    if (color === `gray`) {
      return ` color: #828385; `;
    }
    return null;
  }}

  ${({ weight }) => {
    if (weight === `normal`) {
      return ` font-weight: 400 !important; `;
    }
    if (weight === `thin`) {
      return ` font-weight: 100 !important; `;
    }
    if (weight === `black`) {
      return ` font-weight: 900 !important; `;
    }
    return `font-weight: 700 !important;`;
  }}

  ${({ size }) => {
    if (size === `s1`) {
      return `
        font-size: 48px;
        ${breakpoint(1160)} {
          font-size: 4.14vw;
        }
        ${breakpoint(`mobile`)} {
          font-size: 26px;
        }
      `;
    }
    if (size === `s1-5`) {
      return `
          font-size: 40px;
          ${breakpoint(1160)} {
            font-size: 3.45vw;
          }
          ${breakpoint(`mobile`)} {
            font-size: 24px;
          }
        `;
    }
    if (size === `s2`) {
      return `
        font-size: 32px;
        ${breakpoint(1160)} {
          font-size: 2.76vw;
        }
        ${breakpoint(`mobile`)} {
          font-size: 20px;
        }
      `;
    }
    if (size === `s3`) {
      return `
        font-size: 24px;
        ${breakpoint(1160)} {
          font-size: 2.07vw;
        }
        ${breakpoint(`tablet`)} {
          font-size: 18px;
        }
      `;
    }
    if (size === `s3-1`) {
      return `
        font-size: 24px;
        ${breakpoint(`tablet`)} {
          font-size: 18px;
        }
      `;
    }
    if (size === `s4`) {
      return `
        font-size: 20px;
        ${breakpoint(1050)} {
          font-size: 1.9vw;
        }
        ${breakpoint(`tablet`)} {
          font-size: 16px;
        }
      `;
    }
    if (size === `s4-1`) {
      return `
        font-size: 20px;
        ${breakpoint(`tablet`)} {
          font-size: 16px;
        }
      `;
    }

    if (size === `s5-l`) {
      return `
        font-size: 16px;
        ${breakpoint(1050)} {
          font-size: 1.9vw;
        }
        ${breakpoint(`mobile`)} {
          font-size: 14px;
        }
      `;
    }

    if (size === `s2-l`) {
      return `
        font-size: 32px;
        ${breakpoint(1160)} {
          font-size: 2.76vw;
        }
        ${breakpoint(`mobile`)} {
          font-size: 28px;
        }
      `;
    }

    // 메인페이지 투명성 폰트
    if (size === 's6') {
      return `
        font-size: 16px;
        ${breakpoint(`mobile`)} {
          font-size: 14px;
        }
      `;
    }
    return ``;
  }}
`;
